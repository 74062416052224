import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';

const max1600 = '@media (max-width:1100px)';
const max930 = '@media (max-width:930px)';

export const SectionContainer = styled('section')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  justifyContent: 'space-between',
  padding: '0 20px 0 111px',

  'h1, h2, p': {
    margin: 0,
    padding: 0,
  },

  [max1600]: {
    flexDirection: 'column',
    gap: '24px',
    paddingInline: '40px',
  },
  marginTop: '110px',

  [max930]: {
    padding: '0 20px',
    paddingInline: '20px',
  },
}));

export const LeftAside = styled('aside')(({ theme }) => ({
  width: '100%',
  maxWidth: '390px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // gap: '30px',
  zIndex: 999,
  marginTop: '60px',

  [max1600]: {
    maxWidth: '100%',
    marginTop: '0px',
  },
}));

export const Subtitle = styled('h2')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 300,
  color: '#3A3A3A',
  marginBottom: '8px !important',

  [max1600]: {
    textAlign: 'center',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: '45px',
  lineHeight: '45px',
  fontWeight: 600,
  color: '#414141',

  [max930]: {
    fontSize: '36px',
  },

  [max1600]: {
    textAlign: 'center',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '16.64px',
  lineHeight: '22px',
  fontWeight: 300,
  color: '#3A3A3A',
  margin: '30px 0 !important',

  [max930]: {
    fontSize: '14px',
  },

  [max1600]: {
    margin: '40px 0 50px 0 !important',
    textAlign: 'center',
  },
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',

  [max1600]: {
    marginBottom: '30px',
    alignItems: 'center',
  },
}));

export const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  width: '446px',
  height: '100%',
  maxHeight: '40px',
  background: '#fff',

  borderRadius: '50px',
  border: 'none',
  boxShadow: '7px 7px 20px 6px #5050501A',
  padding: '10px',
  paddingLeft: '20px',

  zIndex: 999,

  [max1600]: {
    width: '97%',
  },
}));

export const StyledInput = styled('input')(() => ({
  border: '0',
  outline: '0',
  background: 'transparent',
  width: '100%',
  height: '100%',
  padding: '0',
  fontSize: '12px',
  fontWeight: 500,
  color: '#3A3A3A',
}));

export const StyledButton = styled(Button)(() => ({
  background: '#000',
  borderRadius: '42px',
  width: '100%',
  maxWidth: '100px',
  height: '40px',
  color: '#fff',
  fontSize: '.8rem',

  '&:hover': {
    background: '#000',
    opacity: 0.8,
  },
}));

export const Tags = styled('p')(() => ({
  fontSize: '9px',
  fontWeight: 400,
  color: '#727272',
  display: 'flex',
  whiteSpace: 'nowrap',
  gap: '4px',
}));

export const StyledLink = styled(Link)(() => ({
  textDecorationColor: '#727272',
  color: '#727272',
}));

export const RightAside = styled('aside')(({ theme }) => ({
  width: '100%',
  height: '550px',
  maxHeight: '814px',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '46px',
  [max1600]: {
    height: '400px',
  },
  [max930]: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: '680px',
    height: '377px',
  },
}));

export const StyledVideo = styled('video')(({ theme }) => ({
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
  marginInline: 'auto',
}));
