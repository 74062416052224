import { Button, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/navigation';

import {
  Section,
  Content,
  Title,
  Description,
  Plans,
  Plan,
  RightSide,
  LeftSide,
  Person1,
  Person4,
  Person2,
  Person3,
  Person5,
  PersonContainer,
} from './RightPlan.styles';
import { useTheme } from '@mui/material/styles';

import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';

export const RightPlan = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const router = useRouter();

  function handlePlansPage() {
    router.push('/plans');
  }

  return (
    <Section>
      {/* Content */}
      <Content>
        <LeftSide>
          {/* Plans */}
          <HiddenComponent hidden={!isSm}>
            <Plans>
              {plans.map((plan, i) => (
                <Plan key={plan + i}>{plan}</Plan>
              ))}
            </Plans>
          </HiddenComponent>

          {/* Title */}
          <Title>Diversos planos para complementar sua produção</Title>

          {/* Description */}
          <Description>
            Tenha acesso a vídeos cinematográficos cheios de brasilidade e
            diversidade para utilizar em todas as mídias, inclusive na TV, e
            economize nas produções de seus comerciais.
          </Description>

          {/* Plans */}
          <HiddenComponent hidden={isSm}>
            <Plans>
              {plans.map((plan, i) => (
                <Plan key={plan + i}>{plan}</Plan>
              ))}
            </Plans>
          </HiddenComponent>

          <Button
            variant="contained"
            onClick={handlePlansPage}
            style={{
              backgroundColor: '#FFF',
              borderRadius: '42px',
              height: '64px',
              width: '100%',
              maxWidth: '310px',
              color: '#000',
              fontSize: '20px',
              fontWeight: 500,

              ...(isSm && {
                color: '#222',
                maxWidth: '100%',
              }),
            }}
          >
            Conheça os planos
          </Button>
        </LeftSide>

        <RightSide>
          <Person1 src="/images/person-1.png" alt="" />
          <Person2 src="/images/person-2.png" alt="" />
          <PersonContainer>
            <Person3 src="/images/person-3.png" alt="" />
            <Person4 src="/images/person-4.png" alt="" />
          </PersonContainer>
          <Person5 src="/images/person-5.png" alt="" width={281} height={367} />
        </RightSide>
      </Content>
    </Section>
  );
};

const plans = ['Start •', 'Plus •', 'Pro •', 'Enterprise'];
