import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  marginTop: '130px',
  marginBottom: '140px',
  paddingInline: '120px',

  p: {
    margin: 0,
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    margin: '20px 0 100px',
    gap: '80px',
    paddingInline: '20px',
  },
}));

export const LeftSide = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
  maxWidth: '374px',
  height: '100%',
  marginTop: '30px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginTop: '0px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 600,
  color: '#414141',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));
export const ColoredText = styled('span')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 500,
  color: '#414141',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    color: '#4b9ed6',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '16.64px',
  lineHeight: '22px',
  fontWeight: 300,
  color: '#3A3A3A',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  borderRadius: '42px',
  width: '100%',
  maxWidth: '185px',

  '&:hover': {
    backgroundColor: '#000',
    opacity: 0.8,
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const RightSide = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5vw',

  [theme.breakpoints.down('md')]: {
    width: '90%',
    gap: '7vw',
  },

  img: {
    objectPosition: 'center',
  },
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
  objectPosition: 'center',
  // width: '75px',
  // height: '75px',

  [theme.breakpoints.down('md')]: {
    width: '50px',
    height: '50px',
  },

  [theme.breakpoints.down('md')]: {
    width: '35px',
    height: '35px',
  },
}));

export const StyledAncineImage = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
  objectPosition: 'center',
  width: '130px',
  height: '95px',
  borderRight: 'solid 2px rgb(231, 231, 231)',
  paddingRight: '60px',

  [theme.breakpoints.down('md')]: {
    width: '90px',
    height: '55px',
    paddingRight: '30px',
  },
}));
