import { styled } from '@mui/material/styles';

const max1024 = '@media (max-width:1024px)';

export const Section = styled('section')(({ theme }) => ({
  paddingInline: '20px',

  p: {
    margin: 0,
  },

  [theme.breakpoints.down('md')]: {
    paddingInline: '20px',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',

  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  borderRadius: '50px',
  color: '#fff',

  backgroundImage: "url('/images/right-plan-background.jpg')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',

  [theme.breakpoints.down('md')]: {
    borderRadius: '64px',
    flexDirection: 'column',
  },
}));

export const LeftSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  textAlign: 'start',
  gap: '30px',
  borderRadius: '50px',
  paddingBlock: '80px',
  paddingLeft: '90px',
  color: '#fff',

  [theme.breakpoints.down('md')]: {
    order: '2',
    borderRadius: '64px',
    paddingInline: '30px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '32px',
  fontWeight: 500,
  color: '#FFF',
  width: '100%',
  maxWidth: '675px',

  [theme.breakpoints.down('md')]: {
    fontSize: '31px',
    lineHeight: '41px',
  },
}));

export const Description = styled('p')(() => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 300,
  color: '#fff',
  width: '100%',
  maxWidth: '534px',

  [max1024]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export const Plans = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',

  background:
    'linear-gradient(90deg, rgb(243, 31, 154) 0%, rgb(59, 124, 251) 100%)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
}));

export const Plan = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 600,

  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 500,
  },
}));

export const RightSide = styled('div')(({ theme }) => ({
  paddingLeft: '55px',
  paddingRight: '10px',

  width: '1000px',
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',

  img: {
    borderRadius: '24px',
    objectFit: 'cover',
  },

  [max1024]: {
    gap: '8px',
    // display: 'none',
    order: '1',
    position: 'relative',
    left: '-65px',
  },
}));

export const Person1 = styled('img')(({ theme }) => ({
  // width: '246px',
  width: '15.81vw',
  height: '401px',
  marginTop: '110px',
  marginBottom: '-20px',

  [max1024]: {
    marginTop: '180px',
    width: '25.81vw',
    height: '300px',
  },
}));

export const Person2 = styled('img')(({ theme }) => ({
  // width: '225px',
  width: '13.71vw',
  height: '296px',
  marginTop: '15px',

  [max1024]: {
    marginTop: '90px',
    width: '19.81vw',
    height: '200px',
  },
}));

export const PersonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginTop: '-38px',

  [max1024]: {
    marginTop: '40px',
  },
}));

export const Person3 = styled('img')(({ theme }) => ({
  // width: '175px',
  width: '11.11vw',
  height: '190px',

  [max1024]: {
    width: '18.81vw',
    height: '130px',
  },
}));

export const Person4 = styled('img')(({ theme }) => ({
  // width: '175px',
  width: '11.11vw',
  height: '190px',

  [max1024]: {
    width: '18.81vw',
    height: '120px',
  },
}));

export const Person5 = styled('img')(({ theme }) => ({
  // width: '281px',
  width: '14.63vw',
  height: '367px',
  marginTop: '53px',

  [max1024]: {
    marginTop: '160px',
    width: '24.81vw',
    height: '200px',
  },
}));
