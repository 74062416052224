import Image from 'next/image';

import {
  SectionContainer,
  LeftAside,
  RightAside,
  Subtitle,
  Title,
  Description,
  SearchContainer,
  Tags,
  StyledInput,
  InputContainer,
  StyledButton,
  StyledLink,
  StyledVideo,
} from './NewWayToSearch.styles';
import { Search } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

export const NewWayToSearch = () => {
  const [search, setSearch] = useState('');
  const router = useRouter();

  const handleSearch = () => {
    if (!search) return;

    router.push(`/search?search_text=${search}`);
  };

  return (
    <SectionContainer id="new-way-to-search">
      {/* Left Side */}
      <LeftAside>
        {/* Title */}
        <Title>
          {/* Subtitle */}
          <Subtitle>O Brasil que você procurava está aqui</Subtitle>
          Um banco
        </Title>
        <Title>de vídeos cheio de brasilidade</Title>
        {/* Description */}
        <Description>
          Chega de passar sufoco com seu banco de imagens. A Bamboo Stock reune
          os melhores vídeos nacionais cheios de brasilidade para você usar em
          todas as suas criações.
        </Description>
        {/* Search Component */}
        <SearchContainer>
          <InputContainer>
            <Search style={{ color: '#969696', fontSize: '28px' }} />
            <StyledInput
              type="text"
              placeholder="Pesquise por Bahia, Agro..."
              onChange={(e) => setSearch(e.target.value)}
            />

            <StyledButton variant="contained" onClick={handleSearch}>
              Buscar
            </StyledButton>
          </InputContainer>
          <Tags>
            Principais Tags:{' '}
            {tags.map((tag, index) => (
              <StyledLink href={`/search?search_text=${tag}`} key={tag + index}>
                {tag}
              </StyledLink>
            ))}
          </Tags>
        </SearchContainer>
      </LeftAside>

      {/* Right Side */}
      <RightAside>
        {/* Image */}

        <StyledVideo autoPlay loop muted>
          <source
            src="https://bamboo-ecommerce-public.s3.sa-east-1.amazonaws.com/static/bmb_home_v10.mp4"
            type="video/mp4"
          />
        </StyledVideo>
      </RightAside>
    </SectionContainer>
  );
};

const tags = ['familia', 'pessoas', 'lugares', 'cidade felicidade'];
