import React, { useCallback } from 'react';
import Carousel from './Carousel';
import { css } from '@emotion/css';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useClips } from '@bamboo/core-lib/src/repo/clips';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Playlist } from '@bamboo/core-lib/api/types';
import type { CarouselItem } from './Carousel';
import type { Theme } from '@/themes/mui/mui.theme';

const TextContainer = styled('div')(({ theme }) => ({
  marginTop: '6px',
  padding: '14px',
  textAlign: 'start',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    boxSizing: 'content-box',
    padding: '0',
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    marginBottom: '0px',
    bottom: '40px',
    left: '40px',
  },
}));

const ExplorePlaylist = styled('p')(({ theme }) => ({
  boxSizing: 'border-box',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(255, 255, 255)',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Title = styled('h2')(({ theme }) => ({
  color: '#3A3A3A',
  fontSize: '13px',
  fontWeight: '600',
  padding: 0,
  lineHeight: 1,
  margin: 0,
  [theme.breakpoints.down('md')]: {
    boxSizing: 'border-box',
    lineHeight: 1,
    padding: '0px',
    margin: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    color: 'rgb(255, 255, 255)',
    fontSize: '25px',
    fontWeight: 700,
    letterSpacing: '-1px',
  },
}));

const Description = styled('h2')(({ theme }) => ({
  color: '#151515',
  fontSize: '11px',
  fontWeight: '400',
  padding: 0,
  lineHeight: 1,
  margin: '4px 0 0 0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ShortListContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  position: 'relative',
  animationDuration: '1.25s',
  animationName: 'fadeIn',
  width: '100%',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '0px 0px 45px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: '0 25px',
}));

const SmallCard = styled('div')(() => ({
  boxSizing: 'border-box',
  transition: 'opacity 0.6s ease 0s, filter 0.6s ease 0s',
  display: 'flex',
  wordBreak: 'break-word',
  paddingBottom: '10px',
  flex: '1',
  width: '100%',
  paddingRight: '0px',
  paddingLeft: '0px',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SmallCardLink = styled(Link)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  cursor: 'pointer',
  hyphens: 'manual',
  position: 'relative',
  padding: '0px',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const SmallCardImage = styled('img')(() => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'medium none',
  maxWidth: '100%',
  width: '100%',
  verticalAlign: 'middle',
  display: 'inline-block',
  borderRadius: '30px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.27) -18px 18px 48px -2px',
  objectFit: 'cover',
  objectPosition: 'center',
}));

const styles = (theme: Theme) => ({
  image: {
    height: '20vh',
    [theme.breakpoints.down('xl')]: {
      height: '17vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '14vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '74vh',
    },
    maxHeight: '600px',
  },
  itemContainer: {
    [theme.breakpoints.down('md')]: {
      '&:after': {
        content: "''",
        width: '100%',
        height: '100%',
        left: 0,
        bottom: 0,
        position: 'absolute',
        background:
          'linear-gradient(to top,rgba(0,0,0,.7) 0%,rgba(0,0,0,0) 80px)',
        borderRadius: '25px',
      },
    },
  },
});

const defaultLabels = {
  explorePlaylist: 'Explore a coleção',
};

export type HomeCarouselProps = {
  labels?: {
    explorePlaylist?: string;
  };
};

const HomeCarousel = (props: HomeCarouselProps) => {
  const { labels = defaultLabels } = props;
  const [isSM, setIsSM] = React.useState(false);
  const theme = useTheme();
  const router = useRouter();
  const classes = styles(theme);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const { playlists } = useClips();

  const onClick = useCallback(
    (index: number, e?: React.MouseEvent<HTMLDivElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      const item = playlists[index];
      router.push(`/playlists/${item.slug}`);
    },
    [playlists, router]
  );

  const [secondaryList, carouselItemList] = React.useMemo(() => {
    if (isSM) {
      const l1 = playlists.slice(0, 6);
      const carouselItemList = l1.map((item) => {
        return {
          image: item.image,
          label: <CarouselLabel item={item} label={labels.explorePlaylist} />,
        } as CarouselItem;
      });
      const l2 = playlists.slice(6, 10);
      return [l2, carouselItemList];
    }

    const carouselItemList = playlists.map((item) => {
      return {
        image: item.image,
        label: <CarouselLabel item={item} label={labels.explorePlaylist} />,
        name: item.name,
        slug: item.slug,
      } as CarouselItem;
    });

    return [[], carouselItemList];
  }, [playlists, isSM]);

  React.useEffect(() => {
    setIsSM(isSmallerScreen);
  }, [isSmallerScreen]);

  return (
    <div>
      <Carousel
        autoplay
        infinite
        centerMode
        swipeToSlide
        autoplaySpeed={4000}
        onClickItem={onClick}
        list={carouselItemList}
        classes={{
          image: css(classes.image),
          itemContainer: css(classes.itemContainer as any),
        }}
        slidesToShowInBreakpoints={{
          xl: 3,
          sm: 1.1,
        }}
      />
      <HiddenComponent hidden={!isSM}>
        <ShortListContainer>
          {secondaryList.splice(0, 3).map((pl) => (
            <SmallCard key={pl.slug}>
              <SmallCardLink href={`/playlists/${pl.slug}`}>
                <SmallCardImage
                  alt={pl.slug}
                  src={pl?.image ?? ''}
                  title={pl.name}
                  width={362}
                  height={165}
                  sizes="(max-width: 480px) 100vw, 480px"
                />
                <TextContainer
                  style={{
                    marginLeft: '15px',
                  }}
                >
                  <Title>{pl.name}</Title>
                </TextContainer>
              </SmallCardLink>
            </SmallCard>
          ))}
        </ShortListContainer>
      </HiddenComponent>
    </div>
  );
};

const CarouselLabel = ({ item, label }: { item: Playlist; label?: string }) => {
  return (
    <TextContainer>
      <Title>{item.name}</Title>
    </TextContainer>
  );
};

export default HomeCarousel;
