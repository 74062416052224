import {
  Section,
  LeftSide,
  Title,
  Description,
  RightSide,
  StyledButton,
  ColoredText,
  StyledImage,
  StyledAncineImage,
} from './NoLimits.styles';
import { Button } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

export const NoLimits = () => {
  const router = useRouter();

  return (
    <Section>
      {/* Left Side */}
      <LeftSide>
        {/* Title */}
        <Title>Aprovado para TV</Title>
        {/* Description */}
        <Description>
          Com um acerco 100% nacional, você pode criar um comercial inteiro para
          TV sem se preocupar com os 20% de limite de utilização de banco de
          imagens.
        </Description>
      </LeftSide>

      {/* Right Side */}
      <RightSide>
        <StyledAncineImage
          src="/images/ancine-logo.svg"
          width={130}
          height={94}
          alt="Ancine Logo"
        />
        {/* <div
          style={{
            height: '120px',
            width: '2px',
            backgroundColor: 'rgb(231, 231, 231)',
          }}
        ></div> */}

        <StyledImage
          src="/images/sbt.jpg"
          width={75}
          height={75}
          alt="Logo SBT"
        />
        <StyledImage
          src="/images/globo.jpg"
          width={75}
          height={75}
          alt="Logo Globo"
        />
        <StyledImage
          src="/images/record.jpg"
          width={75}
          height={75}
          alt="Logo Record"
        />
      </RightSide>
    </Section>
  );
};
