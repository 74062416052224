import React, { useCallback } from 'react';
import Carousel from '../../../Carousel';
import { css } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import type { CarouselItem } from '../../../Carousel';
import type { Theme } from '@/themes/mui/mui.theme';

const styles = (theme: Theme) => ({
  image: {
    width: '523px !important',
    height: '590px !important',
    borderRadius: '50px',
  },
  listItem: {
    marginInline: '22px !important',
  },
});

type CategoriesCarouselProps = {
  list: CarouselItem[];
};

export const FilmmakersCarousel = ({ list }: CategoriesCarouselProps) => {
  const theme = useTheme();
  const router = useRouter();
  const classes = styles(theme);

  const onClick = useCallback(
    (index: number, e?: React.MouseEvent<HTMLDivElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      const item = list[index];
      router.push(`/filmmaker/${item.slug}`);
    },
    [list, router]
  );

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '523px',
        height: '590px',
      }}
    >
      <Carousel
        autoplay
        infinite
        centerMode
        swipeToSlide
        autoplaySpeed={4000}
        onClickItem={onClick}
        list={list}
        classes={{
          image: css(classes.image),
          listItem: css(classes.listItem),
        }}
        slidesToShowInBreakpoints={{
          xl: 1,
          lg: 1,
          md: 1,
          sm: 1,
        }}
      />
    </div>
  );
};
