import { styled } from '@mui/material/styles';

const max930 = '@media (max-width:930px)';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'between',
  alignItems: 'center',
  gap: '20px',
  marginTop: '60px',
  padding: '20px 2vw 0 9vw',
  // paddingInline: '127px 20px',

  ['@media (max-width: 1101px)']: {
    paddingInline: '40px',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginBottom: '5px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '14.63px',
  fontWeight: 600,
  color: '#3A3A3A',
  paddingLeft: '11px',
  borderLeft: '2px solid #969696',
  width: '100%',
  maxWidth: '170px',
  [theme.breakpoints.down('md')]: {
    borderLeft: '0',
    paddingLeft: '0',

    paddingTop: '11px',
    borderTop: '2px solid #969696',
    textAlign: 'center',

    display: 'none',
  },
}));

export const Partners = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '10px',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    display: 'grid',
    gridTemplateColumns: '40px 40px 60px',
    columnGap: '80px',
  },
}));

export const ContainerTextMobile = styled('div')(({ theme }) => ({
  display: 'none',

  [max930]: {
    marginTop: '40px',
    display: 'table',
    textAlign: 'center',
    color: '#414141',
  },
}));

export const H1TextMobile = styled('div')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: '600',
}));
export const H4TextMobile = styled('h4')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: '400',
}));
