import React from 'react';
import { getParamByBreakpoint } from '@bamboo/ts-utils';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { Settings } from 'react-slick';
import type { ParamBreakpoints } from '@bamboo/ts-utils';

const Container = styled('div')(() => ({
  alignItems: 'center',
  alignSelf: 'center',
  width: '100%',
  '& div': {
    cursor: 'pointer',
  },
  '& div :focus-visible': {
    outline: 'none',
  },
}));

const ListItem = styled('div')(({ theme }) => ({
  margin: '0 5px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '0',
    margin: 5,
    marginBottom: 15,
  },
}));

const Card = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
}));

const CardImage = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  borderRadius: '25px',
  verticalAlign: 'middle',
  display: 'inline-block',
  maxWidth: '100%',
  border: 'none',
  boxShadow: '-3px 14px 14px -6px rgba(152,152,152,.23)',
  height: '100%',
}));

export type CarouselItem = {
  image: string;
  label?: React.ReactNode;
  name: string;
  slug: string;
};

export type CustomCarouselProps = {
  list: CarouselItem[];
  slidesToShowInBreakpoints?: ParamBreakpoints<number>;
  classes?: {
    root?: string;
    listItem?: string;
    itemContainer?: string;
    image?: string;
  };
  styles?: {
    root?: React.CSSProperties;
    listItem?: React.CSSProperties;
    itemContainer?: React.CSSProperties;
    image?: React.CSSProperties;
  };
  onClickItem?: (index: number, e?: React.MouseEvent<HTMLDivElement>) => void;
} & Settings;

function generateItems(length: number) {
  return Array.from({ length }, (_, index) => `empty_card_${index}`);
}

let dragging = false;
const CustomCarousel = (props: CustomCarouselProps) => {
  const {
    list,
    classes,
    styles,
    onClickItem,
    afterChange,
    slidesToShow,
    slidesToShowInBreakpoints,
    ...others
  } = props;
  const [slideCount, setSlideCount] = React.useState<number>();
  const theme = useTheme();
  const screenSize = theme.breakpoints.keys.filter((key) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMediaQuery(theme.breakpoints.only(key), { noSsr: true })
  )?.[0];
  const slider = React.useRef<Slider>(null);

  const handlePause = React.useCallback(() => {
    slider?.current?.slickPause?.();
  }, [slider]);

  React.useEffect(() => {
    const bp = slidesToShowInBreakpoints;
    const ss = slidesToShow;
    setSlideCount(
      Math.floor(bp ? getParamByBreakpoint(screenSize, 1, bp) : ss ?? 1)
    );
  }, [screenSize, slidesToShow, slidesToShowInBreakpoints]);

  return (
    <Container className={classes?.root}>
      {/* @ts-ignore */}
      <Slider
        ref={slider}
        infinite
        swipeToSlide
        centerMode
        arrows={false}
        centerPadding={slideCount === 1 ? '20px' : '0px'}
        onSwipe={() => {
          dragging = true;
          handlePause();
        }}
        afterChange={(i) => {
          dragging = false;
          afterChange?.(i);
        }}
        slidesToShow={slideCount}
        {...others}
      >
        {list.map((item, index) => (
          <div
            key={index}
            style={{
              width: 'fit-content',
            }}
          >
            <ListItem style={styles?.listItem} className={classes?.listItem}>
              <Card
                style={styles?.itemContainer}
                className={classes?.itemContainer}
                onClick={(e) => {
                  if (!dragging) {
                    onClickItem?.(index, e);
                  }
                }}
              >
                <CardImage
                  style={styles?.image}
                  className={classes?.image}
                  src={item.image}
                  title={item.name}
                  alt={item.slug}
                />
                {item?.label}
              </Card>
            </ListItem>
          </div>
        ))}
        {generateItems(Math.ceil(slideCount ?? 0) - list.length).map((key) => (
          <div key={key} />
        ))}
      </Slider>
    </Container>
  );
};

export default CustomCarousel;
