import { styled } from '@mui/material/styles';
import Image from 'next/image';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '5px',
  marginTop: '120px',
  marginBottom: '60px',
  padding: '0 20px 0 80px',
  p: {
    margin: 0,
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '60px',
    marginBottom: '20px',
    gap: '46px',
    paddingInline: '0',
  },
}));

export const Row = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  position: 'relative',
  width: '100vw - 40px',
  maxHeight: '370px',
}));

export const RowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '373px',
  height: '100%',
  maxHeight: '253px',

  position: 'relative',

  img: {
    width: '100%',
    maxWidth: '373px',
    height: '100%',
    maxHeight: '253px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '32px',
  },

  p: {
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: 600,
    color: '#fff',
  },

  span: {
    position: 'absolute',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    top: '20px',
    left: '20px',
    background: '#00B99D',
    borderRadius: '4px',

    width: '30px',
    height: '16px',

    fontSize: '8px',
    lineHeight: '16px',
    fontWeight: 500,
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',

    img: {
      maxWidth: '100%',
    },
  },
}));

export const RowItemLabel = styled('p')(() => ({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  fontSize: '16px',
  lineHeight: '17px',
  fontWeight: 600,
  color: '#fff',
}));

export const RowItemImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '373px',
  height: '100%',
  maxHeight: '253px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '32px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

export const RowItemBadge = styled('span')(() => ({
  position: 'absolute',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  top: '20px',
  left: '20px',
  background: '#00B99D',
  borderRadius: '4px',

  width: '30px',
  height: '16px',

  fontSize: '8px',
  lineHeight: '16px',
  fontWeight: 500,
}));

const VerticalLine = styled('div')(({ theme }) => ({
  width: '8px',
  borderRadius: '999px',
  height: '84px',
  background: '#B15437',
  position: 'absolute',
  top: '0',
  left: '-32px',

  [theme.breakpoints.down('md')]: {
    height: '8px',
    width: '84px',
    left: '0',
    top: '-32px',
  },
}));

export const VerticalLine1 = styled(VerticalLine)(() => ({
  background: '#B15437',
}));

export const VerticalLine2 = styled(VerticalLine)(({ theme }) => ({
  background: '#2D4736',
  height: '46px',

  [theme.breakpoints.down('md')]: {
    width: '46px',
  },
}));

export const VerticalLine3 = styled(VerticalLine)(({ theme }) => ({
  background: '#61001B',
  height: '46px',
  [theme.breakpoints.down('md')]: {
    width: '46px',
  },
}));
