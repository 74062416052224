import { styled } from '@mui/material/styles';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '75px',
  marginTop: '50px',
  marginBottom: '138px',

  width: '100%',

  p: {
    margin: 0,
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingInline: '40px',

  gap: '50px',

  [theme.breakpoints.down('md')]: {
    paddingInline: '20px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '65px',
  fontHeight: '75px',
  fontWeight: 500,
  color: '#2a2a2a',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
  },
}));

export const Subtitle = styled('p')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 300,
  color: '#000000',
  maxWidth: '60%',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

export const FilmmakersContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  width: '110vw',
  //   maxHeight: '445px',

  [theme.breakpoints.up('md')]: {
    width: '132vw',
  },
}));

export const FilmmakerCard = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '388px',
  height: '388px',
}));

export const Image = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: '50px',
  objectPosition: 'center',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '65px',
  left: '50px',
}));

export const Name = styled('p')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  color: '#fff',
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: '#fff',
  paddingRight: '50px',
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '95px',
  width: 'fit-content',
  paddingInline: '40px',
  marginTop: '-180px',

  [theme.breakpoints.up('sm')]: {
    // marginLeft: '-115px',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    // marginLeft: '0px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '0px',
  },
}));

export const FooterItem = styled('div')(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  maxWidth: '309px',

  [theme.breakpoints.down('md')]: {
    marginTop: '0',
  },
}));

export const FooterTitle = styled('p')(({ theme }) => ({
  width: 'fit-content',
  fontSize: '34px',
  fontWeight: 500,
  color: '#000',
}));

export const FooterDescription = styled('p')(({ theme }) => ({
  width: 'fit-content',
  fontSize: '16px',
  fontWeight: 300,
  color: '#000',
}));
