import { styled } from '@mui/material/styles';
import Link from 'next/link';

export const TextContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '20px',

  [theme.breakpoints.down('md')]: {
    boxSizing: 'content-box',
    padding: '0',
    width: '100%',
    zIndex: 1,
    marginBottom: '0px',
  },
}));

export const ExplorePlaylist = styled('p')(({ theme }) => ({
  boxSizing: 'border-box',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(255, 255, 255)',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const Title = styled('h2')(() => ({
  fontSize: '1.5vw',
  lineHeight: '17px',
  fontWeight: 500,
  color: '#fff',
}));

export const Description = styled('h2')(({ theme }) => ({
  color: '#151515',
  fontSize: '11px',
  fontWeight: '400',
  padding: 0,
  lineHeight: 1,
  margin: '4px 0 0 0',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const ShortListContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  position: 'relative',
  animationDuration: '1.25s',
  animationName: 'fadeIn',
  width: '100%',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '0px 0px 45px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: '0 25px',
  height: '253px',
}));

export const SmallCard = styled('div')(() => ({
  boxSizing: 'border-box',
  transition: 'opacity 0.6s ease 0s, filter 0.6s ease 0s',
  display: 'flex',
  wordBreak: 'break-word',
  paddingBottom: '10px',
  flex: '1',
  width: '373px',
  height: '253px',
  paddingRight: '0px',
  paddingLeft: '0px',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const SmallCardLink = styled(Link)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  hyphens: 'manual',
  position: 'relative',
  padding: '0px',
  maxWidth: '450px',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '373px',
  height: '253px',
}));

export const SmallCardImage = styled('img')(() => ({
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'medium none',
  maxWidth: '100%',
  width: '373px',
  height: '253px',
  verticalAlign: 'middle',
  display: 'inline-block',
  borderRadius: '30px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.27) -18px 18px 48px -2px',
  objectFit: 'cover',
}));
